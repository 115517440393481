import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoBW from "-!svg-react-loader?props[]=className:logo!../images/svg/im_logo_bw.svg";

const Header = ({ siteTitle }) => (
  <header>
    <Link to="/?secret=portfolio2020">
      <LogoBW />
    </Link>
    <div className="links">
      <Link to="/?secret=portfolio2020" activeClassName="active">
        Home
      </Link>
      <Link to="/portfolio?secret=portfolio2020" activeClassName="active">
        Work
      </Link>
      <Link to="/about?secret=portfolio2020" activeClassName="active">
        About
      </Link>
      <a href="#contact" activeClassName="active">
        Contact
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
