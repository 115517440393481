import { Link } from "gatsby"
import React from "react"

const Contact = () => (
  <div className="contact-section"  id="contact">
   <hr />
   <p>Interested in collaborating? <a href="mailto:hello@iranmateu.com">Say hello.</a></p>
  </div>
)

export default Contact